import React, { useEffect, useState } from 'react';
import { DefaultSelect, Checkbox, Button } from 'ui-kit-takeat';
import {
  Container,
  OrderArea,
  Footer,
  FooterContent,
  Body,
  AddClientDiv,
} from './styles';
import { FaUserPlus } from 'react-icons/fa';
import { ProductDetails } from './ProductDetails';
import { useCart } from '~/context/OrderingSystem/Cart';
import toast from 'react-hot-toast';
import api from '~/services/api';
import { useBalanceContext } from '~/pages/BalancePage';
import formatValue from '~/utils/formatValue';
import { useAuth } from '~/context/AuthContext';
import { FaPencil } from 'react-icons/fa6';

export const BalanceOrderArea = () => {
  const {
    weightProducts,
    commandTotal,
    command,
    getMenu,
    user,
    setAddClient,
    getTables,
    setWeightProductSelected,
  } = useBalanceContext();

  const [loadingOrder, setLoadingOrder] = useState(false);
  const [printOrder, setPrintOrder] = useState(false);
  const [weightOptions, setWeightOption] = useState([{}]);
  const [selectedWeightProduct, setSelectedWeightProduct] = useState({});
  //const [optionSelected,setOptionSelected] = useState(weightOptions[0] ||{});
  const {
    increment,
    decrement,
    removeFromCart,
    cart,
    closeCart,
    addCartToOrder,
  } = useCart();
  // const [isTablePrintable, setIsTablePrintable] = useState(
  //   user?.print_balcony_default
  // );
  const { buyerCreatedByWaiter } = useAuth();

  async function handleAddToOrder() {
    if (cart.length <= 0) {
      toast.error('Adicione produtos ao carrinho para finalizar o pedido');
      return;
    }

    setLoadingOrder(true);
    try {
      const res = await addCartToOrder(
        command.id,
        user.id,
        'in_person',
        null,
        null,
        null,
        null,
        printOrder, //isTablePrintable    se for isTablePrintable,ele sempre vai imprimir a via de produção,não sei se esse e o desejado,
        command.id,
        getMenu,
        null,
        null,
        user.user_id,
        null,
        null,
        null
      );

      if (res.status === 200) {
        closeCart();
      }

      if (res.data.tableBill.id) {
        if (printOrder) {
          await api.post('restaurants/printers/printer-queue', {
            table_session_id: res.data.tableBill.id,
            close_after_print: false,
          });
        }
      }

      getTables();
    } catch (error) {
      console.log(error);
    }
    setLoadingOrder(false);
  }

  function handleAddWeightProducts(e) {
    const selectedProduct = weightProducts.find((prod) => prod.id === e.value);
    setWeightProductSelected(selectedProduct);
    setSelectedWeightProduct(e);
    localStorage.setItem(
      'selectedWeightProduct',
      JSON.stringify(selectedProduct)
    );
  }

  useEffect(() => {
    const optionTeste = [];
    if (weightProducts.length > 0) {
      weightProducts.map((product) => {
        const produto = {
          label: product.name,
          value: product.id,
          price: product.price,
        };
        optionTeste.push(produto);
      });
    }
    setWeightOption(optionTeste);
  }, [weightProducts]);

  useEffect(() => {
    const savedProduct = localStorage.getItem('selectedWeightProduct');
    const parseSavedProdut = JSON.parse(savedProduct);
    const savedSelectedProduct = weightOptions?.find((prod) => {
      return prod.value === parseSavedProdut?.id;
    });

    setSelectedWeightProduct(savedSelectedProduct);
    if (selectedWeightProduct?.value) {
      setWeightProductSelected(parseSavedProdut);
    }
  }, [weightOptions]);
  return (
    <Container>
      <DefaultSelect
        options={weightOptions}
        containerStyles={{
          display: 'block',
          padding: 16,
          borderBottom: '1px solid #F6F6F6',
        }}
        onChange={(e) => handleAddWeightProducts(e)}
        value={selectedWeightProduct}
      />
      <Body>
        <AddClientDiv>
          {buyerCreatedByWaiter ? (
            <>
              <span id="addClient" onClick={() => setAddClient(true)}>
                {buyerCreatedByWaiter.name} - {buyerCreatedByWaiter.phone}
              </span>
              <FaPencil color="#2ec9b7" />
            </>
          ) : (
            <>
              <span id="addClient" onClick={() => setAddClient(true)}>
                Adicionar Cliente
              </span>
              <FaUserPlus color="#2ec9b7" />
            </>
          )}
        </AddClientDiv>
        <OrderArea>
          <span>Resumo do pedido</span>
        </OrderArea>
        <ProductDetails
          cart={cart}
          increment={increment}
          decrement={decrement}
          removeFromCart={removeFromCart}
        />
      </Body>

      <Footer>
        <Checkbox
          label="Imprimir comanda"
          customColor="#545454"
          labelStyles={{ color: '#545454' }}
          containerStyles={{
            borderBottom: '1px solid #EDEDED',
            borderTop: '1px solid #EDEDED',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 5,
            padding: '10px 16px',
          }}
          checked={printOrder}
          onClick={() => setPrintOrder(!printOrder)}
        />

        <FooterContent>
          <div>
            <span style={{ fontSize: 16, fontWeight: 600 }}>Total:</span>
            <span style={{ color: '#43AF50', fontSize: 16, fontWeight: 600 }}>
              {formatValue(commandTotal)}
            </span>
          </div>

          <div>
            <Button
              title="Limpar"
              inverted={true}
              onClick={() => closeCart()}
              buttonColor="#c8131b"
              containerStyles={{ borderRadius: 8 }}
              textStyle={{ fontWeight: 600 }}
              isLoading={loadingOrder}
              disabled={loadingOrder}
            />

            <Button
              title="Confirmar"
              buttonColor="#2EC9B7"
              onClick={() => handleAddToOrder()}
              containerStyles={{ flexGrow: 1, borderRadius: 8 }}
              textStyle={{ fontWeight: 600 }}
              isLoading={loadingOrder}
              disabled={loadingOrder}
            />
          </div>
        </FooterContent>
      </Footer>
    </Container>
  );
};
