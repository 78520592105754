import React, { useEffect, useState, useCallback } from 'react';
import { ProductsArea } from './ProductsArea';
import {
  Header,
  InputArea,
  CategoryArea,
  CategoryName,
  ButtonAction,
  ButtonGoToCart,
  CountBadge,
  ButtonDiv,
} from './styles';
import { Button, DefaultInput, InformationDiv } from 'ui-kit-takeat';
import logo_icon from '../../../assets/img/logo_icon.png';
import { useCart } from '~/context/OrderingSystem/Cart';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useBalanceContext } from '~/pages/BalancePage';
export const BalanceCart = () => {
  const {
    userMenu,
    command,
    setSelectedCategory,
    selectedCategory,
    setSelectedProduct,
    setIsSelectingComplements,
    cart,
    weightProductSelected,
    user,
  } = useBalanceContext();

  const [price, setPrice] = useState(weightProductSelected.price || 0);
  const [weight, setWeight] = useState('0');
  const [cartAmount,setCartAmount] = useState(0);
  const { addToCart } = useCart();

  //const cartAmount = cart.reduce((accum, curr) => accum + curr.amount, 0);
  
  useEffect(()=> {
    setCartAmount(cart.reduce((accum, curr) => accum + curr.amount, 0));
  },[cart]);

  useEffect(() => {
    if (userMenu.length > 0) {
      setSelectedCategory(userMenu[0]);
    }
  }, [userMenu]);

  function handleAddWeightProducts(peso) {
    if (Object.keys(weightProductSelected).length === 0) {
      toast.error('Selecione um produto no peso');
      return;
    } else if (weight === '0' || weight === '') {
      toast.error('Insira um peso');
      return;
    }
    const productFormatted = {
      ...weightProductSelected,
      price: weightProductSelected.price,
      name: weightProductSelected.name,
    };
    addToCart({
      product: productFormatted,
      amount: 1,
      observation: '',
      complementsCategory: [],
      isProductWeight: true,
      inputProductWeight: peso,
      inputProductPrice: price,
    });
  }

  useEffect(() => {
    if (Object.keys(weightProductSelected).length > 0) {
      
      const weightNumber = weight ? parseFloat(weight.replace(',', '.')) : 0;
      const priceNumber = weightProductSelected.price ? parseFloat(weightProductSelected.price) : 0;

      const newPrice = weightNumber * priceNumber;
      setPrice(newPrice.toFixed(2));
    }
  }, [weight, weightProductSelected]);

  const getWeightBalance = useCallback(async () => {
    try {
      const response = await axios.post('http://127.0.0.1:9600/', {
        port_name: user.balance.port_name,
        baud_rate: user.balance.baud_rate,
        bit_rate: user.balance.bit_rate,
      });
      setWeight(response.data.peso?.replace('.',','));
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  useEffect(() => {
    if (user.balance && Object.keys(weightProductSelected).length > 0) {
      const intervalId = setInterval(() => {
        getWeightBalance();
      }, 500);

      return () => clearInterval(intervalId);
    }
  }, [getWeightBalance, weightProductSelected]);

  useEffect(()=>{
    const cartHaveWeightProduct = cart.some((prod) => prod.use_weight === true && !prod.complement_categories.length > 0);
    if(Object.keys(weightProductSelected).length > 0 && !cartHaveWeightProduct && weight !== '0'){
      handleAddWeightProducts(weight);
    };
  },[price])
  return (
    <div
      style={{
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header>{command !== '' ? `COMANDA Nº ${command.label || command.table_number}` : ''}</Header>
      <div style={{padding:"8px 16px 10px"}}>
      <InformationDiv 
        title='O primeiro item pesável é adicionado automaticamente á comanda/mesa. '
        text='Para adicionar outros itens na mesma comanda, basta substituir o produto na balança e clicar no botão "adicionar".'
        type='info'
        />
      </div>
      <InputArea>
        <DefaultInput
          title="Peso(Kg)"
          placeholder="00,00"
          style={{ height: 40, padding: '6px 20px' }}
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
        <DefaultInput
          title="Valor(R$)"
          placeholder="00,00"
          style={{ height: 40, padding: '6px 20px' }}
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          disabled={true}
        />
        <Button
          title='Adicionar'
          buttonColor='#2EC9B7'
          containerStyles={{height:40,padding:'6px 20px'}}
          onClick={() => handleAddWeightProducts(weight)}
        />
      </InputArea>
      <CategoryArea id="category-area">
        {userMenu?.map((category) => {
          const selectCategory = selectedCategory.id === category.id;
          return (
            <CategoryName
              selectedCategory={selectCategory}
              key={category.id}
              onClick={() => {
                setSelectedCategory(category);
              }}
            >
              {category.name}
            </CategoryName>
          );
        })}
      </CategoryArea>
      <ProductsArea
        category={selectedCategory}
        setSelectedProduct={setSelectedProduct}
        setIsSelectingComplements={setIsSelectingComplements}
        weight={weight}
      />
      <div style={{ flexGrow: 1 }}></div>
      {/* <ButtonAction onClick={() => handleAddWeightProducts(weight)}>
        <ButtonDiv>
          <ButtonGoToCart>
            <img src={logo_icon} alt="logo da takeat" />
            {!!cart.length > 0 && <CountBadge>{cartAmount}</CountBadge>}
          </ButtonGoToCart>
          <span>Pedir</span>
        </ButtonDiv>
      </ButtonAction> */}
    </div>
  );
};
